<template>
  <div class="device-index">
    <div class="device-department">
      <div class="device-group" @click="handleSyncDepartment">企业微信 <i class="el-icon-refresh"></i></div>
      <p style="font-size: 12px; color: #999;text-align: center;margin-bottom: 8px;">
        <span class="remark-circle"></span> 表示仅有微信官方名称
      </p>
      <tree-depart  @node-click="handleNodeClick" ref="group"></tree-depart>
    </div>

    <div class="device-list" v-show="!deviceShow && !deviceDrinkShow">
      <el-row class="dept-container">
        <el-col :span="16" class="dept-container-title">
          <span class="dept-container-title-name">可用名称:{{group.label}}</span>

          <el-button @click="handleDeptName()" size="mini" type="text" class="device-btn-add">
            <i class="el-icon-edit"></i> 名称修改
          </el-button>

          <el-button @click="handleDeviceSelect(true)" size="mini" type="text" class="device-btn-add"
                     v-show="activeName === '授权设备'">
            <i class="el-icon-circle-plus"></i> 设备授权
          </el-button>

          <el-button size="mini" type="text" class="device-btn-add" v-show="activeName === '授权设备'">
            <span class="filter-title" v-popover:popover>
              <i class="el-icon-date"></i> 订单时段
            </span>
            <el-popover
              ref="popover"
              placement="right"
              trigger="hover">
              <el-date-picker
                @change="loadDeviceOrUserSearch"
                size="small"
                v-model="timeVal"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-popover>
          </el-button>
          <span v-if="activeName === '授权设备' && timeVal.length > 1" class="device-info">
            ( {{timeVal[0].Format('yyyy/MM/dd')}} - {{timeVal[1].Format('yyyy/MM/dd')}} )
          </span>
        </el-col>
        <el-col :span="8" class="dept-container-radio">
          <span style="color: #999; font-size: 12px; margin-right: 10px;">(点击按钮即可切换不同视图)</span>
          <el-radio-group v-model="activeName" size="mini" @change="handleChangeDepartment">
            <el-radio-button label="授权设备"></el-radio-button>
            <el-radio-button label="部门员工"></el-radio-button>
          </el-radio-group>
        </el-col>
        <el-col class="dept-container-title">
          <p style="font-size: 12px; color: #999;">
            微信官方:
            <department-name type="departmentName" :openid="group.wxId"></department-name>
          </p>
        </el-col>
      </el-row>
      <el-table
        v-show="activeName === '授权设备'"
        :data="devices">
        <empty slot="empty"></empty>
        <el-table-column label="设备编号" align="center" prop="uuid" min-width="80"></el-table-column>
        <el-table-column label="设备名称" align="center" prop="name" min-width="120"></el-table-column>
        <el-table-column label="订单数" align="center" prop="orders" min-width="60"></el-table-column>
        <el-table-column label="设备状态" align="center">
          <template slot-scope="scope">
            <div class="user-info">
                            <span :class="[{ offline: scope.row.status ==1 }, { normal: scope.row.status ==2 },
                                    { warning: scope.row.status ==3 },
                                    { fault: scope.row.status ==4 }]">
                                   <span class="circle"></span> {{scope.row.status | filterName}}
                                </span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="设备类型" align="center" prop="productName"></el-table-column>
        <el-table-column label="设备IMEI" align="center" prop="imei" min-width="100px"></el-table-column>
        <el-table-column label="设备更新时间" align="center" min-width="100px">
          <template slot-scope="scope">
            {{ new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text"
                       size="small"
                       @click="handleDeviceBindDepartment(scope.row.uuid)">
              绑定其他部门
            </el-button>
            <el-button size="small" type="text"
                       class="device-edit" @click="handleDeviceUnbind(scope.row.uuid, scope.$index)">
              取消绑定
            </el-button>
          </template>
        </el-table-column>
      </el-table>


      <el-table
        v-show="activeName !== '授权设备'"
        :data="users">
        <empty slot="empty"></empty>
        <el-table-column label="姓名" align="center" prop="name">
          <template slot-scope="scope">
            <department-name type="userName" :openid="scope.row.wxId"></department-name>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" class="device-edit" @click="handleUserDeviceBind(scope.row.wxId)">绑定设备</el-button>
            <el-button type="text" class="device-edit" @click="handleUserDevice(scope.row.wxId)">可用设备</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="handleCurrentChange"
        v-show="activeName !== '授权设备'"
        class="pagination"
        :current-page="pagination.curPage"
        :page-size="pagination.limit"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>

      <c-dialog :visible.sync="bindDept.visible" :width="'42rem'" :height="'32rem'">
        <div slot="title" class="input-title">
          <p class="input-title-text">绑定部门</p>
          <div class="input-title-container">
            <el-button size="mini" type="primary" plain @click="handDeptBindDevice">关联设备</el-button>
          </div>


        </div>
        <div style="margin-top: 48px;"></div>
        <el-checkbox :indeterminate="bindDept.indeterminate" v-model="bindDept.checkAll"
                     @change="handleCheckAllChange">全选
        </el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="bindDept.wxId" @change="handleCheckedWxIdChange">
          <el-checkbox v-for="department in departments" :label="department.id" :key="department.id">
            <department-name type="departmentName" :openid="department.id"></department-name>
          </el-checkbox>
        </el-checkbox-group>
      </c-dialog>
      <c-dialog :visible.sync="bindVisible" :width="'42rem'" :height="'36rem'">
        <div slot="title" class="input-title">
          <p class="input-title-text">绑定设备</p>
          <div class="input-title-container">
            <el-button size="mini" type="primary" plain @click="handleBindDepartment">关联设备</el-button>
          </div>

          <div class="input-title-filter">
            <p>过滤条件:</p>
            <el-input v-model="bind.name" placeholder="请输入设备名称" @change="handleInputChange" size="mini"/>
          </div>

        </div>

        <devices ref="devices" :corpid="employee.corpid" :code="employee.code"></devices>
      </c-dialog>

      <slider :visible.sync="userDeviceVisible"
              :width="'72%'">
        <div class="employee-list">
          <el-table
            :data="userDevices">
            <empty slot="empty"></empty>
            <el-table-column label="设备编号" align="center" prop="uuid" min-width="100"></el-table-column>
            <el-table-column label="设备名称" align="center" prop="name" min-width="120"></el-table-column>
            <el-table-column label="设备状态" align="center">
              <template slot-scope="scope">
                <div class="user-info">
                            <span :class="[{ offline: scope.row.status ==1 }, { normal: scope.row.status ==2 },
                                    { warning: scope.row.status ==3 },
                                    { fault: scope.row.status ==4 }]">
                                   <span class="circle"></span> {{scope.row.status | filterName}}
                                </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="设备类型" align="center" prop="productName"></el-table-column>
            <el-table-column label="设备IMEI" align="center" prop="imei" min-width="100px"></el-table-column>
            <el-table-column label="设备创建时间" align="center" min-width="120px">
              <template slot-scope="scope">
                {{ new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}
              </template>
            </el-table-column>

            <el-table-column label="操作" align="center">
              <template slot-scope="scope">

                <el-button type="text" class="device-edit"
                           @click="handleUserDeviceUnbind(scope.row.uuid, scope.$index)">
                  取消绑定
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            class="pagination"
            @current-change="handleUserCurrentChange"
            :current-page="userPagination.curPage"
            :page-size="userPagination.limit"
            layout="total, prev, pager, next, jumper"
            :total="userPagination.total">
          </el-pagination>
        </div>
      </slider>

    </div>


  </div>
</template>

<script>
  import {
    workDepartmentDevices,
    workDepartmentSync,
    workDepartmentDeviceBind,
    workDepartmentDeviceUnbind,
    workDepartmentEmployeeUnion,
    workDepartmentEmployeeList,
    departmentRename,
    workDepartmentList,
    workDepartmentBindDevice,
  } from '@/api/work/department'

  import {
    workUserDeviceBind,
    workUserDeviceUnbind,
    workUserDeviceUnion,
    workUserDeviceList,
  } from '@/api/work/user'

  import TreeDepart from '@/components/TreeDepart/index.vue'
  import Devices from '@/components/Device'
  import CDialog from '@/components/Dialog'
  import Slider from '@/components/Slider'
  import DepartmentName from '@/components/DepartmentName'
  import {mapGetters} from "vuex";

  export default {
    name: '',
    components: {
      TreeDepart,
      Devices,
      CDialog,
      Slider,
      DepartmentName
    },
    computed: {
      ...mapGetters(['employee']),
    },
    mounted: function () {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      this.timeVal = [start, end]

      // departments
    },

    activated() {
      workDepartmentList({
        corpid: this.employee.corpid,
        code: this.employee.code,
        start: 0, limit: 100
      }).then(res => {
        this.departments = res.data || []
      })
    },
    data: function () {
      return {
        devices: [],
        userDevices: [],
        activeName: '授权设备',
        timeVal: [],
        bindVisible: false,
        userDeviceVisible: false,
        isDepartment: true,
        drawer: false,
        departments: [],
        bindDept: {
          visible: false,
          indeterminate: false,
          checkAll: false,
          wxId: [],
          deviceUuid: ''
        },
        bind: {
          uuid: '',
          name: ''
        },
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        pagination: {
          name: '',
          wxId: 0,
          uuid: '',
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
        },
        userPagination: {
          name: '',
          uuid: '',
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
        },
        users: [],
        group: {
          wxId: '',
          uuid: '',
          label: '',
        },
        groups: [],
        deviceShow: false,
        deviceDrinkShow: false,
        deviceIndex: 0,
      }
    },
    filters: {
      filterName(status) {
        switch (status) {
          case 1:
            return '离线'
          case 2:
            return '正常'
          case 3:
            return '待维护'
          case 4:
            return '待维修'
        }
      },
    },
    methods: {
      handleCheckAllChange(val) {
        let wxId = []
        this.departments.forEach(dept => {
          wxId.push(dept.id)
        })
        this.bindDept.wxId = val ? wxId : [];
        this.bindDept.isIndeterminate = false;
      },

      handleCheckedWxIdChange(value) {
        let checkedCount = value.length;
        this.bindDept.checkAll = checkedCount === this.departments.length;
        this.bindDept.isIndeterminate = checkedCount > 0 && checkedCount < this.departments.length;
      },
      handleDeptName() {
        this.$prompt('请输入部门名称', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({value}) => {
          departmentRename({
            code: this.employee.code,
            corpid: this.employee.corpid,
            wxId: this.group.wxId + '',
            name: value,
          }).then(res => {
            this.group.label = value
          })
        }).catch(() => {
        });
      },
      handleNodeClick(node) {
        if (this.pagination.wxId === node.id) {
          return
        }

        this.group.wxId = node.id
        this.group.label = node.realName
        this.loadDeviceOrUserSearch()
      },

      handleDeviceSelect(isDepartment) {
        this.bindVisible = true
        this.isDepartment = isDepartment
        this.$nextTick(function () {
          this.$refs.devices.loadDevices(this.bind.name)
        })
      },

      handleDeviceUnbind(uuid, index) {
        this.$confirm('取消绑定当前部门不可免费制作饮品, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          workDepartmentDeviceUnbind({
            deviceUuid: uuid,
            wxId: this.group.wxId,
            corpid: this.employee.corpid,
            code: this.employee.code,
          }).then(res => {
            this.devices.splice(index, 1)
          })
        }).catch(() => {

        })

      },
      handleSyncDepartment() {
        workDepartmentSync({
          code: this.employee.code,
          corpid: this.employee.corpid,
        }).then(res => {
          this.$message.success('同步成功')
          this.loadDeviceOrUserSearch()
          this.$refs.group.loadDepartment()
        })
      },

      loadDeviceOrUserSearch() {
        this.pagination.wxId = this.group.wxId
        this.pagination.uuid = this.group.uuid

        switch (this.activeName) {
          case "授权设备":
            let startAt = (this.timeVal[0].getTime() / 1000).toFixed(0);
            let endAt = (this.timeVal[1].getTime() / 1000).toFixed(0);
            workDepartmentDevices({
              wxId: this.group.wxId,
              code: this.employee.code,
              corpid: this.employee.corpid,
              startAt: startAt, endAt: endAt
            }).then(res => {
              this.devices = res.data || []
            })
            break
          case "部门员工":
            let corpid = this.employee.corpid
            let code = this.employee.code
            workDepartmentEmployeeUnion({...this.pagination, corpid, code}).then(res => {
              this.users = res.data.list || []
              this.pagination.total = parseInt(res.data.total)
            })

        }
      },

      handDeptBindDevice() {
        workDepartmentBindDevice({
          corpid: this.employee.corpid,
          code: this.employee.code,
          wxId: this.bindDept.wxId,
          deviceUuid: this.bindDept.deviceUuid,
        }).then(res => {
          this.bindDept.visible = false
          this.bindDept.wxId = []
          this.bindDept.isIndeterminate = false
          this.bindDept.checkAll = false
          this.$message.success('绑定成功')
        })
      },

      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        let corpid = this.employee.corpid
        let code = this.employee.code
        workDepartmentEmployeeList({...this.pagination, corpid, code}).then(res => {
          this.users = res.data || []
        })
      },

      handleUserCurrentChange(value) {
        this.userPagination.curPage = value
        this.userPagination.start = (value - 1) * this.userPagination.limit
        let corpid = this.employee.corpid
        let code = this.employee.code
        workUserDeviceList({...this.userPagination, corpid, code}).then(res => {
          this.userDevices = res.data || []
        })
      },

      handleBindDepartment() {
        let deviceUuid = this.$refs.devices.getDevices() || []
        if (deviceUuid.length === 0) {
          this.$message.warning("请先选取需要绑定的设备")
          return
        }
        if (this.isDepartment) {
          workDepartmentDeviceBind({
            code: this.employee.code,
            corpid: this.employee.corpid,
            wxId: this.group.wxId,
            deviceUuid: deviceUuid
          }).then(res => {
            this.bindVisible = false
            this.$message.success('绑定成功')
            this.loadDeviceOrUserSearch()
          })
        } else {
          workUserDeviceBind({
            wxId: this.bind.uuid,
            code: this.employee.code,
            corpid: this.employee.corpid,
            deviceUuid: deviceUuid
          }).then(res => {
            this.bindVisible = false
            this.$message.success('绑定成功')
          })
        }

      },

      handleInputChange(value) {
        this.$refs.devices.loadDevices(value)
      },

      handleChangeDepartment() {
        this.loadDeviceOrUserSearch()
      },

      handleUserDeviceBind(uuid) {
        this.handleDeviceSelect(false)
        this.bind.uuid = uuid
      },

      handleUserDeviceUnbind(uuid, index) {
        this.$confirm('仅可取消用户授权设备, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          workUserDeviceUnbind({
            deviceUuid: uuid,
            code: this.employee.code,
            corpid: this.employee.corpid,
            wxId: this.userPagination.uuid
          }).then(res => {
            this.userDevices.splice(index, 1)
          })
        }).catch(() => {

        })
      },

      handleDeviceBindDepartment(uuid) {
        this.bindDept.deviceUuid = uuid
        this.bindDept.visible = true
      },

      handleUserDevice(userid) {
        this.userPagination.uuid = userid
        this.userPagination.code = this.employee.code,
          this.userPagination.corpid = this.employee.corpid,
          workUserDeviceUnion(this.userPagination).then(res => {
            this.userPagination.total = parseInt(res.data.total)
            this.userDevices = res.data.list || []
            this.userDeviceVisible = true
          })
      },
    },
  }
</script>

<style lang="scss">
  .device-index {
    display: flex;

    .remark-circle {
      display: inline-block;
      width: 4px;
      height: 4px;
      background: $_theme_color;
      border-radius: 50%;
      margin-right: 6px;
    }

    .device-department {
      width: 240px;
      padding-right: $padding;
      border-right: 1px solid;
      @include set_border_color($--border-color, $--border-color-dark);

      .device-group {
        width: 100%;
        text-align: center;
        @include set_font_color($_theme_color, $--font-color-dark);
        @include set_bg_color(#eef8ff, #454954);
        cursor: pointer;
        padding: 4px 0;
        border-radius: $border-radius;
        margin-bottom: 4px;
      }

      .device-group:hover {
        font-weight: 500;
      }
    }

    .employee-list {
      padding: $padding;
    }

    .device-list {
      flex: 1;
      padding-left: $padding;

      .device-info {
        font-size: 12px;
        font-weight: 400;
        color: #999;
        margin-left: $padding-4;
      }

      .dept-container {
        border-bottom: 1px solid;
        padding-bottom: 16px;
        @include set_border_color($--border-color, $--border-color-dark);

        &-title {
          font-weight: 500;
          padding-left: $padding;
          display: flex;

          &-name {
            display: inline-block;
            width: 160px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }

        &-radio {
          text-align: right;
        }

        .device-btn-add {
          margin-left: $padding;
        }
      }


      .pagination {
        text-align: right;
        margin-top: $padding-8;

        .el-pagination__total {
          float: left;
        }
      }

      .device-edit, .device-detail {
        cursor: pointer;
        display: inline-block;
        margin-right: $padding-8;
        font-size: 13px;
        font-weight: 400;
      }

      .device-edit:hover, .device-detail:hover {
        font-weight: 500;
      }

      .device-edit {
        color: $btn_color;
        cursor: pointer;
      }

      .device-detail {
        color: $_theme_color;
        cursor: pointer;
      }

      .circle {
        border-radius: $font_little;
        width: $font_little;
        height: $font_little;
        display: inline-block;
        margin-right: 5px;
      }

      .offline {
        color: #5B8FF9;

        .circle {
          background-color: #5B8FF9;
        }
      }

      .normal {
        color: #5AD8A6;

        .circle {
          background-color: #5AD8A6;
        }
      }

      .warning {
        color: #F6BD16;

        .circle {
          background-color: #F6BD16;
        }
      }

      .fault {
        color: #E8684A;

        .circle {
          background-color: #E8684A;
        }
      }
    }
  }
</style>
