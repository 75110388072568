/**
 * Created by fworld on 2019/9/25.
 */
import request from '@/util/request'


export const thirdCompanies = (params) => {
  return request({
    'url': '/api/v1/work/third/companies',
    'method': 'GET',
    params
  })
}

export const thirdAgentInit = (data) => {
  return request({
    'url': '/api/v1/work/third/agent/init',
    'method': 'POST',
    data
  })
}

export const thirdCompanyList = (params) => {
  return request({
    'url': '/api/v1/work/third/company/list',
    'method': 'GET',
    params
  })
}

export const thirdCompanyUnion = (params) => {
  return request({
    'url': '/api/v1/work/third/company/union',
    'method': 'GET',
    params
  })
}

export const thirdCompanyAllocateDevice = (data) => {
  return request({
    'url': '/api/v1/work/third/company/allocate/device',
    'method': 'POST',
    data
  })
}


export const thirdCompanyRemoveDevice = (params) => {
  return request({
    'url': '/api/v1/work/third/company/remove/device',
    'method': 'GET',
    params
  })
}

export const thirdCompanyDeviceUnion = (params) => {
  return request({
    'url': '/api/v1/work/third/company/device/union',
    'method': 'GET',
    params
  })
}

export const thirdCompanyDeviceList = (params) => {
  return request({
    'url': '/api/v1/work/third/company/device/list',
    'method': 'GET',
    params
  })
}

export const thirdCompanyRole = (params) => {
  return request({
    'url': '/api/v1/work/third/company/role',
    'method': 'GET',
    params
  })
}

export const thirdCompanyIm = (params) => {
  return request({
    'url': '/api/v1/work/third/company/im',
    'method': 'GET',
    params
  })
}





